<template>
    <div>
        <div>
            <span style="font-weight: bolder;font-size: 30px;">发电机状态：</span>
            <el-image :src="deviceImage" style="width: 30px;height: 30px"></el-image>
        </div>
        <div class="dg-status-item">
            <el-button size="small" type="success" @click="handleDeviceOption(1)">启动</el-button>
            <div class="light" :class="{greenLight:deviceStart}"></div>
        </div>
        <div class="dg-status-item">
            <el-button size="small" type="success" @click="handleDeviceOption(2)">停止</el-button>
            <div class="light" :class="{greenLight:deviceStop}"></div>
        </div>
        <div class="dg-status-item">
            <el-button size="small" type="success" @click="handleDeviceOption(3)">自动</el-button>
            <div class="light" :class="{greenLight:deviceAuto}"></div>
        </div>
        <div class="dg-status-item">
            <el-button size="small" type="success" @click="handleDeviceOption(4)">手动</el-button>
            <div class="light" :class="{greenLight:deviceManual}"></div>
        </div>
        <div class="dg-status-item">
            <el-button size="small" type="success" @click="handleDeviceOption(5)">{{deviceSwitchText}}</el-button>
            <div class="light" :class="{greenLight:deviceSwitch}"></div>
        </div>
    </div>
</template>

<script>
    import {deviceControl, queryEngineReportData} from '@/api/station/deviceInfoApi';
    import message from '@/utils/message';
    import loading from '@/utils/cmdLoading';
    import websocket from '@/utils/websocket';
    import {getToken} from '@/api/auth';

    export default {

        name: 'device-dg-cmd',
        props: {

            deviceId: {

                type: Number
            },
            deviceNumber: {

                type: String
            }
        },
        data() {

            return {

                topic: '/user/' + getToken() + '/deviceCmd',
                deviceImage: '',
                deviceSwitchText: '',
                deviceStart: false,
                deviceStop: false,
                deviceAuto: false,
                deviceManual: false,
                deviceSwitch: false,
                deviceOnImage: require('../../../../assets/image/on.png'),
                deviceOffImage: require('../../../../assets/image/off.png'),
            }
        },
        methods: {

            initPage() {

                console.log('this.deviceId:' + this.deviceId);
                console.log('this.deviceNumber:' + this.deviceNumber);
                this.unSub();
                websocket.initWebSocket(this.topic, this.wsCallback);
                this.deviceImage = this.deviceOffImage;
                this.queryEngineReportData(this.deviceNumber);
            },
            queryEngineReportData(deviceNumber) {

                let params = {

                    deviceNum: deviceNumber
                };
                queryEngineReportData(params).then((res) =>{

                    if (res.data != null) {

                        // 根据发动机状态判断
                        if (res.data.genStatus === '待机') {

                            this.deviceImage = this.deviceOffImage;
                            this.deviceStart = true;
                        } else {

                            this.deviceImage = this.deviceOnImage;
                            this.deviceStop = true;
                        }
                        // 0-自动 1-手动
                        if (res.data.genMode === '0') {

                            this.deviceManual = true;
                        } else {

                            this.deviceAuto = true;
                        }
                        // 0-分闸 1-合闸
                        if (res.data.genSwitch === '1') {

                            this.deviceSwitch = false;
                            this.deviceSwitchText = '分闸';
                        } else {

                            this.deviceSwitch = true;
                            this.deviceSwitchText = '合闸';
                        }
                    }
                }).catch(err => {

                    console.error(err);
                    message.error(err);
                });
            },
            // websocket回调方法
            wsCallback() {

                if (this.cmdLoading) {

                    this.cmdLoading.close();
                }
                message.success('指令下发成功');
                this.queryEngineReportData(this.deviceNumber);
            },
            handleDeviceOption(id) {

                let params = null;
                if (id === 1) {

                    params = {

                        deviceNumber: this.deviceNumber,
                        deviceWholeId: this.deviceId + '',
                        deviceType: '6',
                        commandIndex : '1'
                    };
                } else if (id === 2) {

                    params = {

                        deviceNumber: this.deviceNumber,
                        deviceWholeId: this.deviceId + '',
                        deviceType: '6',
                        commandIndex : '0'
                    };
                } else if (id === 3) {

                    params = {

                        deviceNumber: this.deviceNumber,
                        deviceWholeId: this.deviceId + '',
                        deviceType: '11',
                        commandIndex : '0'
                    };
                } else if (id === 4) {

                    params = {

                        deviceNumber: this.deviceNumber,
                        deviceWholeId: this.deviceId + '',
                        deviceType: '11',
                        commandIndex : '1'
                    };
                } else if (id === 5) {

                    // 使用websocket发送设置指令
                    let cmdIndex = '1';
                    if (this.deviceSwitch === true) {

                        cmdIndex = '0';
                    }
                    params = {

                        deviceNumber: this.deviceNumber,
                        deviceWholeId: this.deviceId + '',
                        deviceType: '10',
                        commandIndex : cmdIndex
                    };
                }
                console.error(JSON.stringify(params));
                this.cmdLoading = loading.loading('指令下发中...', 60);
                if (params != null) {

                    deviceControl({message: JSON.stringify(params)}).then((res) => {

                        if (res.code !== '100') {

                            message.error(res.msg);
                        }
                    }).catch(err => {

                        console.error(err);
                        message.error(err);
                    });
                }
            },
            unSub() {

                websocket.unSubTopic(this.topic);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .light {

        width: 15px;
        height: 15px;
        margin-left: 20px;
        border-radius:50%;
        line-height: 2.5;
        color: white;
        text-align: center;
        background-color: #d5040d;
    }
    .greenLight {

        background-color: #67c23a;
    }
    .dg-status-item {

        display: flex;
        align-items: center;
        margin-top: 20px;
        margin-left: 50px;
    }
</style>
